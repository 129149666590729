<template>
  <v-dialog :value="showDialog" max-width="500px">
    <v-card :loading="loading" style="min-height: 350px" class="d-flex flex-column" tile>
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <div class="d-flex justify-space-between pa-3">
        <v-btn
          :disabled="parent === '/'"
          icon
          plain
          small
          text
          @click="navigateBack(parent)"
        >
          <v-icon>mdi-arrow-left</v-icon>

        </v-btn>
        <span class="mr-5" v-if="!loading">{{ currentPath }}</span>
      </div>
      <v-divider></v-divider>
      <template v-if="!loading">
        <v-list style="max-height: 400px" class="overflow-y-auto">
          <v-list-item-group color="primary">
            <div>
              <v-list-item
                v-for="folder in folderItems"
                :key="folder"
                class="d-flex align-center"
              >
                <v-list-item-icon>
                  <v-btn plain text @click.stop.prevent="navigateToFolder(folder)">
                    <v-icon>mdi-folder-download</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-title
                  @click="doubleClickHandler(folder)"
                >
                  {{ folder }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>

        <v-card flat v-if="folderItems.length === 0">
          <v-card-text class="d-flex flex-column align-center justify-center">
            <v-icon>mdi-folder-open</v-icon>
            <div>{{ $t('contract.onedrive.no_subfolders') }}</div>
          </v-card-text>
        </v-card>

      </template>
    </v-card>
    <v-card flat tile>
      <v-card-actions class="d-flex justify-end pb-2 mr-2">
        <v-btn @click="cancel">{{ $t('general.cancel') }}</v-btn>
        <v-btn large color="primary" @click="selectFolder">
          {{ selectedFolder ? $t('contract.onedrive.select_checked') : $t('contract.onedrive.select_this') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import IcoFolderOpen from '@/components/svg/IcoFolderOpen';
import IcoFolderPlus from '@/components/svg/IcoFolderPlus';

export default defineComponent({
  name: 'FolderStructureExplorer',
  props: {
    value: Boolean,
    loadFunction: {
      required: true,
      type: Function,
    },
    backPath: {
      required: false,
      type: String,
    },
  },
  components: {
    IcoFolderOpen,
    IcoFolderPlus,
  },
  data() {
    return {
      selectedFolder: '',
      timeoutId: null,
      loading: false,
      folderItems: [],
      root: '',
      parent: '',
    };
  },
  computed: {
    splitPath() {
      return this.parent.split('/');
    },
    currentPath() {
      return `${this.splitPath.slice(0).join('/')}`;
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.loadItems('')
  },
  methods: {
    doubleClickHandler(folder) {
      this.selectedFolder = folder

      if(!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          this.timeoutId = null
        }, 500);
      } else {
        clearTimeout(this.timeoutId);
        this.timeoutId = null
        this.navigateToFolder(folder);
      }
    },
    async loadItems(path) {
      this.loading = true;
      try {
        const { parent, children } = await this.loadFunction(path);
        this.parent = parent;
        this.folderItems = children;
      } catch (e) {
        this.loading = false;
      }

      this.loading = false;
    },
    navigateBack(parent) {
      this.selectedFolder = null;

      if (parent === '/') {
        return;
      }

      let parentPath = this.splitPath.slice(0);
      parentPath.pop();
      let path;
      if (parentPath.length > 0) {
        path = `${parentPath.join('/')}`
      } else {
        path = ''
      }
      this.loadItems(path);
    },
    navigateToFolder(folder) {
      this.selectedFolder = null;

      let parentPath = '';
      if (this.splitPath.length > 0) {
        parentPath = `${this.splitPath.slice(0).join('/')}`
      } else {
        parentPath = ''
      }
      const fullPath = `${parentPath}/${folder}`;

      this.loadItems(fullPath);
    },
    selectFolder() {
      let path;
      if (this.selectedFolder) {
        path = `${this.splitPath.slice(0).join('/')}`;
        path = path.length > 1 ? `${path}/${this.selectedFolder}` : this.selectedFolder;
      } else {
        path = this.splitPath.slice(0).join('/');
      }

      path = path.length > 1 ? '/' + path : path;
      this.$emit('selectFolder', path);
    },
    cancel() {
      this.$emit('cancel')
    }
  },
});
</script>

<style lang="scss" scoped></style>
